<template>
    <div>
        <div v-if="data[0].values.length > 0">
            <vue-frappe
                id="test"
                type="line"
                :isNavigable="true"
                :height="300"
                :labels="getLabels()"
                :colors="['#2759c8']"
                :dataSets="this.data"
                :tooltipOptions="{
                    formatTooltipY: d => 'GMD ' + formatPrice(d),
                }"
            ></vue-frappe>
        </div>
    </div>
</template>

<script>
import FormatPrice from '../../mixins/FormatPrice';

export default {
    props: ['stats'],
    mixins: [FormatPrice],
    data() {
        return {
            data: [{ values: [] }],
        };
    },

    mounted() {
        this.getData();
    },

    methods: {
        getLabels() {
            let labels = [];

            Object.entries(this.stats).map(item => {
                labels.push(`${item[0]}`);
            });

            return labels;
        },

        getData() {
            let amtData = [];

            Object.entries(this.stats).map(item => {
                amtData.push(item[1].amount);
            });

            this.data[0].values = amtData;
        },
    },
};
</script>
