<template>
  <div>
    <div class="flex mt-8">
      <div class="w-1/2 border px-4 py-4 mb-4 -mr-2 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Total Sales</h1>
          </div>
        </div>
        <div class="flex text-xl mt-2 text-gray-600">
          GMD {{ formatPrice(data.totalsales) }}
        </div>
      </div>
      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Sales Today</h1>
          </div>
        </div>
        <div class="flex text-xl mt-2 text-gray-600">
          GMD {{ formatPrice(data.salesToday) }}
        </div>
      </div>
      <div class="w-1/2 border px-4 py-4 mb-4 ml-4 rounded text-sm">
        <div class="flex">
          <div class="mr-2">
            <h1 class="text-xl font-bold">Total Commission</h1>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="flex text-xl mt-2 text-gray-600">
            GMD {{ formatPrice(data.totalcommission) }}
          </div>

          <div class="flex text-sm mt-2 text-gray-600">
            <button class="text-blue-600 hover:text-blue-500"></button>
          </div>
        </div>
      </div>
    </div>

    <shortage-repayment
      v-show="isModalVisible"
      modalHeadline="Please confirm?"
      :data="data"
      :confirmMessage="`Are you sure you want to make a deposit of`"
      @confirmEvent="confirmRequest"
      @close="closeConfirmModal"
    />

    <div class="border p-4 rounded">
      <staff-sales />
    </div>
  </div>
</template>

<script>
import StaffSales from "@/components/Charts/StaffSales.vue";
import ShortageRepayment from "../../../components/ShortageRepayment.vue";
import FormatPrice from "@/mixins/FormatPrice";

export default {
  props: ["data"],
  components: { StaffSales, ShortageRepayment },

  mixins: [FormatPrice],

  data: () => ({
    isModalVisible: false,
    isLoading: false,
  }),

  mounted() {
    console.log(this.data);
  },

  methods: {
    showConfirmModal() {
      this.isModalVisible = true;
    },
    closeConfirmModal() {
      this.isModalVisible = false;
      this.$emit("complete");
    },

    confirmRequest(req) {
      console.log("User has confirmed", req);
      this.userHasConfirmed = req;

      if (this.userHasConfirmed == "yes") {
        // this.onSubmit();
      }
    },
  },
};
</script>
