<template>
  <transition name="modal-fade">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="
            inline-block
            align-bottom
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-xl sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-2 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start mb-4">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  Shortage Repayment
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Your current shortage balance is GMD
                    <b>{{ data.total_shortage }}</b>
                  </p>
                </div>
              </div>
            </div>

            <hr />
          </div>

          <form @submit.prevent="onSubmit">
            <!-- Bank -->
            <div class="w-full overflow-hidden px-5">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Bank
                </label>
                <input
                  class="
                    shadow
                    appearance-none
                    border
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    leading-tight
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="form.bank"
                  type="text"
                  :class="{
                    'is-invalid': form.errors.has('bank'),
                  }"
                  required
                />
              </div>
            </div>

            <div class="w-full overflow-hidden px-5">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Bank Slip Number
                </label>
                <input
                  class="
                    shadow
                    appearance-none
                    border
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    leading-tight
                    uppercase
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="form.bank_slip_number"
                  type="text"
                  :class="{
                    'is-invalid': form.errors.has('bank_slip_number'),
                  }"
                  required
                />
              </div>
            </div>

            <div class="w-full overflow-hidden px-5">
              <!-- Column Content -->
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-normal mb-2"
                  for="username"
                >
                  Enter Repayment Amount
                </label>
                <input
                  class="
                    shadow
                    appearance-none
                    border
                    rounded
                    w-full
                    py-2
                    px-3
                    text-gray-700
                    leading-tight
                    uppercase
                    focus:outline-none focus:shadow-outline
                  "
                  v-model="form.amount"
                  type="text"
                  :class="{
                    'is-invalid': form.errors.has('amount'),
                  }"
                  required
                />
              </div>
            </div>
            <div
              class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
            >
              <button
                :disabled="isLoading"
                type="button"
                @click="onConfirmEvent()"
                class="
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-transparent
                  shadow-sm
                  px-4
                  py-2
                  bg-green-600
                  text-base
                  font-medium
                  text-white
                  hover:bg-green-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-green-500
                  sm:ml-3 sm:w-auto sm:text-sm
                "
              >
                <div v-if="isLoading" class="flex justify-center items-center">
                  <div
                    class="
                      animate-spin
                      rounded-full
                      h-5
                      w-5
                      mr-2
                      border-b-2 border-b-white
                    "
                  ></div>
                </div>
                Submit
              </button>
              <button
                type="button"
                @click="close"
                class="
                  mt-3
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-gray-300
                  shadow-sm
                  px-4
                  py-2
                  bg-white
                  text-base
                  font-medium
                  text-gray-700
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-indigo-500
                  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                "
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Form from "vform";

export default {
  name: "modal",

  props: {
    modalHeadline: {
      type: String,
      required: true,
    },
    confirmMessage: {
      type: String,
      required: true,
    },

    data: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    isLoading: false,

    form: new Form({
      staff: null,
      bank: "VISTA BANK",
      bank_slip_number: "",
      amount: null,
    }),
  }),
  methods: {
    close() {
      this.$emit("close");
    },

    async onConfirmEvent() {
      this.isLoading = true;

      try {
        await this.form.post("/shortage_repayment/store");

        this.isLoading = false;
        this.form.clear();
        this.close();

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },
  },
};
</script>
